<template>
  <div>
    <vue-header title="舌诊" isReturn="true"/>
    <camera :type="type"/>
  </div>
</template>
<script>
  import vueHeader from "@/components/header";
  import camera from "@/components/camera";
  export default {
    name: "step2",
    components: {
      vueHeader,
      camera
    },
    data(){
      return{
          type:2
      }
    },
    created:function(){
        let flow = sessionStorage.getItem('flow');
        if(flow){
          let flow_step = JSON.parse(flow).flow_info.flow_step;
          if(flow_step==13||flow_step==14){
            this.type = 3;
          }
        }
    },
  };
</script>
